import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { BRAND } from '../constants';

const STAGE = process.env.REACT_APP_STAGE;

export function useHotjar(brand) {
  useEffect(() => {
    if (STAGE !== 'prod') {
      return;
    }

    if (brand === BRAND.IBF) {
      // <!-- Hotjar Tracking Code for https://upload.ibusinessfunding.com/ -->
      // <script>
      //     (function(h,o,t,j,a,r){
      //         h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      //         h._hjSettings={hjid:2073810,hjsv:6};
      //         a=o.getElementsByTagName('head')[0];
      //         r=o.createElement('script');r.async=1;
      //         r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      //         a.appendChild(r);
      //     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      // </script>
      console.log('Initializing IBF hotjar');
      hotjar.initialize(2073810, 6);
    } else if (brand === BRAND.KCF) {
      // <!-- Hotjar Tracking Code for https://upload.knightcapitalfunding.com/ -->
      // <script>
      //     (function(h,o,t,j,a,r){
      //         h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      //         h._hjSettings={hjid:2073849,hjsv:6};
      //         a=o.getElementsByTagName('head')[0];
      //         r=o.createElement('script');r.async=1;
      //         r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      //         a.appendChild(r);
      //     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      // </script>
      console.log('Initializing KCF hotjar');
      hotjar.initialize(2073849, 6);
    } else if (brand === BRAND.RC) {
      // <!-- Hotjar Tracking Code for https://upload.readycapital.com/ -->
      // <script>
      //     (function(h,o,t,j,a,r){
      //         h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      //         h._hjSettings={hjid:2073853,hjsv:6};
      //         a=o.getElementsByTagName('head')[0];
      //         r=o.createElement('script');r.async=1;
      //         r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      //         a.appendChild(r);
      //     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      // </script>
      console.log('Initializing RC hotjar');
      hotjar.initialize(2073853, 6);
    } else if (brand === BRAND.NONE) {
      // <!-- Hotjar Tracking Code for https://upload.funding.business/ -->
      // <script>
      //     (function(h,o,t,j,a,r){
      //         h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      //         h._hjSettings={hjid:2073881,hjsv:6};
      //         a=o.getElementsByTagName('head')[0];
      //         r=o.createElement('script');r.async=1;
      //         r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      //         a.appendChild(r);
      //     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      // </script>
      console.log('Initializing NONE hotjar');
      hotjar.initialize(2073881, 6);
    }
  }, [brand]);
}
