import { format } from 'date-fns';

export function getDateInFormat(date, dateFormat) {
  try {
    const newDate = new Date(date);

    return format(newDate, dateFormat);
  } catch (err) {
    console.log('Failed to format', err);
    return '';
  }
}
