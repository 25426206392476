import React, { useEffect, useReducer } from 'react';
import queryString from 'query-string';
import orderBy from 'lodash.orderby';
import { Page } from './components/Page';
import { DocumentsUploader } from './components/DocumentsUploader';
import { useHotjar } from './hooks/use-hotjar';
import { getDocuments } from './services';
import { BRAND } from './constants';

const ACTION = {
  MISSING_ID: 'missing-id',
  REQUEST: 'request',
  SUCCESS: 'success',
  ERROR: 'error',
};

const STATUS = {
  INITIAL: 'initial',
  MISSING_ID: 'missing-id',
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'error',
};

const initialState = {
  status: 'initial',
  documents: null,
  error: null,
};

export default function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { status, documents } = state;

  const parsed = queryString.parse(window.location.search);
  const { id } = parsed || {};

  const brand = getBrand(parsed?.brand);

  useHotjar(brand);

  useEffect(() => {
    if (!id) {
      dispatch({ type: ACTION.MISSING_ID });
    } else {
      dispatch({ type: ACTION.REQUEST });
      getDocuments(id)
        .then((documents) => {
          dispatch({ type: ACTION.SUCCESS, documents });
        })
        .catch((error) => {
          dispatch({ type: ACTION.ERROR, error });
        });
    }
  }, [id]);

  if (status === STATUS.INITIAL || status === STATUS.LOADING) {
    return (
      <Page brand={brand}>
        <div className="text-center text-large">Preparing page...</div>
      </Page>
    );
  }

  if (status === STATUS.MISSING_ID) {
    return (
      <Page brand={brand}>
        <div className="text-center text-large">Please use link from email.</div>
      </Page>
    );
  }

  if (status === STATUS.ERROR) {
    return (
      <Page brand={brand}>
        <div className="text-center text-large">Something went wrong. Please try again later.</div>
      </Page>
    );
  }

  // If there are no Errors and no documents
  // means all of the documents are completed
  if (!documents || !documents.length) {
    return (
      <Page brand={brand}>
        <div className="text-center text-huge">
          Thank you!
          <br />
          We are working on your documents.
        </div>
      </Page>
    );
  }

  return (
    <Page brand={brand}>
      <DocumentsUploader initialDocuments={documents} brand={brand} />
    </Page>
  );
}

function reducer(state, action) {
  switch (action.type) {
    case ACTION.MISSING_ID:
      return { ...state, status: STATUS.MISSING_ID };
    case ACTION.REQUEST:
      return { ...state, status: STATUS.LOADING };
    case ACTION.SUCCESS: {
      return {
        ...state,
        status: STATUS.LOADED,
        documents: orderBy(action.documents, ['type', 'year'], ['asc', 'desc']),
      };
    }
    case ACTION.ERROR:
      return { ...state, status: STATUS.ERROR, error: action.error };
    default:
      return state;
  }
}

function getBrand(brandQueryParam) {
  const hostname = window?.location?.hostname;

  // if we on one of the "allowed" domains
  // we should not care about `brand` query param
  if (hostname && hostname.includes('knightcapitalfunding.com')) {
    return BRAND.KCF;
  }

  if (hostname && hostname.includes('ibusinessfunding.com')) {
    return BRAND.IBF;
  }

  if (hostname && hostname.includes('readycapital.com')) {
    return BRAND.RC;
  }

  if (hostname && hostname.includes('funding.business')) {
    return BRAND.NONE;
  }

  // if it is some "netlify" domain or any other,
  // lets check `brand` query param
  if (brandQueryParam && brandQueryParam === BRAND.KCF) {
    return BRAND.KCF;
  }

  if (brandQueryParam && brandQueryParam === BRAND.IBF) {
    return BRAND.IBF;
  }

  if (brandQueryParam && brandQueryParam === BRAND.RC) {
    return BRAND.RC;
  }

  if (brandQueryParam && brandQueryParam === BRAND.NONE) {
    return BRAND.NONE;
  }

  return BRAND.IBF;
}
