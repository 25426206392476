import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { FileUpload } from './FileUpload';
import { markCompleted, uploadFile } from '../../services';
import './styles.css';

const STATUS = {
  INITIIAL: 'initial',
  UPLOADING: 'uploading',
  DONE: 'done',
  ERROR: 'error',
};

const DOCUMENT_STATUS = {
  INITIAL: 'initial',
  SELECTED: 'selected',
  UPLOADING: 'uploading',
  UPLOADED: 'uploaded',
  ERROR: 'error',
};

export function DocumentsUploader({ initialDocuments = [], brand }) {
  const [documents, setDocuments] = useState(
    initialDocuments.map((document) => {
      return {
        data: document,
        status: DOCUMENT_STATUS.INITIAL,
        rawFile: null,
        brand: brand,
      };
    })
  );
  const [pageStatus, updateStatus] = useState(STATUS.INITIIAL);
  const hasDocuments = !!documents?.length;

  useEffect(() => {
    if (pageStatus === STATUS.DONE) {
      // Lets remove UPLOADED items from `documents` list
      // in case not all files were uploaded in 1 go
      setDocuments((prevState) => {
        return prevState.filter(({ status }) => status !== DOCUMENT_STATUS.UPLOADED);
      });
    }
  }, [pageStatus]);

  const isSubmitEnabled = documents.some(({ rawFile }) => !!rawFile?.name);

  const updateDocumentByItemId = (itemId, attrsToUpdate = {}) => {
    setDocuments((prevState) => {
      return prevState.map((item) => {
        if (item?.data?.itemId === itemId) {
          return {
            ...item,
            ...attrsToUpdate,
          };
        }

        return item;
      });
    });
  };

  async function formSubmit(event) {
    event.preventDefault();

    if (!isSubmitEnabled) {
      return;
    }

    updateStatus(STATUS.UPLOADING);

    let failedFilesCount = 0;
    for (const documentObject of documents) {
      const { data: document, rawFile } = documentObject;

      if (!rawFile?.name) {
        continue;
      }

      try {
        updateDocumentByItemId(document?.itemId, { status: DOCUMENT_STATUS.UPLOADING });
        await uploadFile(document, rawFile);
        await markCompleted({
          id: document?.id,
          itemId: document?.itemId,
        });
        updateDocumentByItemId(document?.itemId, { status: DOCUMENT_STATUS.UPLOADED });
      } catch (error) {
        failedFilesCount++;
        updateDocumentByItemId(document?.itemId, { status: DOCUMENT_STATUS.ERROR });
      }
    }

    if (failedFilesCount === documents.length) {
      updateStatus(STATUS.ERROR);
    } else {
      updateStatus(STATUS.DONE);
    }
  }

  if (pageStatus === STATUS.ERROR) {
    return (
      <div className="text-center text-large">Something went wrong. Please try again later.</div>
    );
  }

  if (pageStatus === STATUS.DONE && !hasDocuments) {
    return (
      <div className="text-center text-huge">
        Thank you!
        <br />
        Documents received.
      </div>
    );
  }

  if (pageStatus === STATUS.UPLOADING) {
    return (
      <>
        <div className="text-center text-large">
          Uploading documents. Please don't refresh the page.
        </div>

        <div className="working-files-table">
          {documents.map(({ data: document, status: documentStatus, rawFile }) => {
            if (documentStatus === DOCUMENT_STATUS.INITIAL) {
              return null;
            }

            const checkmark = '\u2714';
            const isUploaded = documentStatus === DOCUMENT_STATUS.UPLOADED;
            const isUploading = documentStatus === DOCUMENT_STATUS.UPLOADING;
            const isError = documentStatus === DOCUMENT_STATUS.ERROR;

            return (
              <div className="working-files-table__row" key={document?.itemId}>
                <div className="working-files-table__col working-files-table__col--name">
                  {rawFile?.name}
                </div>
                <div
                  className={classNames(
                    'working-files-table__col working-files-table__col--status',
                    {
                      'working-files-table__col--uploaded': isUploaded,
                    }
                  )}
                >
                  {isError && 'X'}
                  {isUploaded && checkmark}
                  {isUploading && <Loading />}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }

  return (
    <>
      {pageStatus === STATUS.INITIIAL && <h1 className="text-center mt-0">Documents Upload</h1>}

      {pageStatus === STATUS.DONE && hasDocuments && (
        <h1 className="text-center mt-0">
          Thank you!
          <br />
          Documents received.
          <br />
          Please upload additional required documents.
        </h1>
      )}

      <form onSubmit={formSubmit}>
        <div className="files-upload-wrapper">
          {documents.map(({ data: document, rawFile, brand }) => {
            return (
              <FileUpload
                key={document?.itemId}
                label={document?.type}
                year={document?.year}
                month={document?.month}
                quarter={document?.quarter}
                version={document?.version}
                description={document?.description}
                dueDate={document?.dueDate}
                type={document?.type}
                file={rawFile}
                brand={brand}
                onChange={(selectedFile) =>
                  updateDocumentByItemId(document?.itemId, {
                    status: selectedFile ? DOCUMENT_STATUS.SELECTED : DOCUMENT_STATUS.INITIAL,
                    rawFile: selectedFile,
                  })
                }
              />
            );
          })}
        </div>

        <div className="btn-submit-wrapper">
          <button
            className="btn btn-primary btn-lg btn-submit"
            disabled={!isSubmitEnabled}
            type="submit"
          >
            Upload
          </button>

          {!isSubmitEnabled && (
            <div className="submit-validation-notice">Please provide at least 1 document</div>
          )}
        </div>
      </form>
    </>
  );
}

function Loading() {
  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
