import React, { useEffect } from 'react';
import { BRAND } from '../../constants';
import ibfLogo from './img/ibf-logo.svg';
import rcLogo from './img/rc-logo.png';
import kcfLogo from './img/kcf-logo.png';
import './styles.css';

export const BRAND_DATA = {
  HOME_SITE: {
    KCF: 'https://knightcapitalfunding.com/',
    IBF: 'https://www.ibusinessfunding.com/',
    RC: 'https://readycapital.com/',
  },
  PHONE: {
    KCF: '877-852-1745',
    IBF: '855-349-2002',
    RC: '855-201-3894',
  },
};

export function Page({ brand = BRAND.IBF, children }) {
  useEffect(() => {
    document.body.classList.add(`brand-${brand}`);
  }, [brand]);

  return (
    <div className="container page">
      {brand === BRAND.KCF && (
        <Header
          logoHref={BRAND_DATA.HOME_SITE.KCF}
          phone={BRAND_DATA.PHONE.KCF}
          logoSrc={kcfLogo}
        />
      )}
      {brand === BRAND.IBF && (
        <Header
          logoHref={BRAND_DATA.HOME_SITE.IBF}
          phone={BRAND_DATA.PHONE.IBF}
          logoSrc={ibfLogo}
        />
      )}
      {brand === BRAND.RC && (
        <Header logoHref={BRAND_DATA.HOME_SITE.RC} phone={BRAND_DATA.PHONE.RC} logoSrc={rcLogo} />
      )}

      <main className="page__body">{children}</main>
    </div>
  );
}

function Header({ logoHref, logoSrc, phone }) {
  return (
    <header className="page__header">
      <a className="logo-link" href={logoHref} target="_blank" rel="noopener noreferrer">
        <img className="logo-img" src={logoSrc} alt="" />
      </a>
      <span className="header__phone">
        <span>Call Now: {phone}</span>
      </span>
    </header>
  );
}
