import axios from 'axios';
import { MIME_TYPES } from './constants';

const STAGE = process.env.REACT_APP_STAGE;

export function getDocuments(id) {
  const url = getUrl(`/v1/missing-docs/${id}`);

  return (
    axios
      .get(url)
      // axios data
      .then((response) => response?.data)
      // KCF API data
      .then((response) => response?.data)
      .catch((error) => {
        return Promise.reject(error.response);
      })
  );
}

export function uploadFile(document, rawFile) {
  const url = getUrl('/v1/missing-docs/signed-url');

  const fileContentType = getFileContentType({ name: rawFile?.name, type: rawFile?.type });

  return (
    axios
      .post(url, {
        id: document?.id,
        itemId: document?.itemId,
        fileName: rawFile?.name,
        contentType: fileContentType,
      })
      // axios data
      .then((response) => response?.data)
      // KCF API data
      .then((response) => response?.data)
      .then((data) => {
        const timeout = 300000; // 5 minutes
        const headers = { 'Content-Type': fileContentType };

        // Facing "Network Error" on some user sessions
        // Lets do retry to see if it helps
        return axios
          .put(data.url, rawFile, { headers, timeout })
          .catch(() => {
            // 2nd try
            return axios.put(data.url, rawFile, { headers, timeout });
          })
          .catch(() => {
            // 3rd try
            // lets try with native Fetch here
            return fetch(data.url, {
              method: 'PUT',
              body: rawFile,
            }).then((response) => {
              // Make sure the response was valid
              if (response.status >= 200 && response.status < 300) {
                return response;
              }

              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            });
          });
      })
      .catch((error) => {
        return Promise.reject(error.response);
      })
  );
}

export function markCompleted({ id, itemId }) {
  const url = getUrl(`/v1/missing-docs/completed`);

  return (
    axios
      .post(url, { id, itemId })
      // axios data
      .then((response) => response?.data)
      .catch((error) => console.log('markCompleted failed', error))
  );
}

function getUrl(path) {
  switch (STAGE) {
    case 'local':
      return `http://localhost:3100/dev${path}`;
    case 'dev':
      return `https://swld7nfitg.execute-api.us-east-1.amazonaws.com/dev${path}`;
    default:
      return `https://83lc6bprj8.execute-api.us-east-1.amazonaws.com/prod${path}`;
  }
}

function getFileContentType({ name, type } = {}) {
  if (type) {
    return type;
  }

  // in some cases type='' on Windows
  // e.g. `text/csv` has type=''
  const ext = getFileExtension(name);

  const extToType = {
    csv: MIME_TYPES.CSV,
    doc: MIME_TYPES.DOC,
    docx: MIME_TYPES.DOCX,
    xls: MIME_TYPES.XLS,
    xlsx: MIME_TYPES.XLSX,
  };

  return extToType[ext] || '';
}

function getFileExtension(name) {
  return name?.split('.')?.pop();
}
