import React from 'react';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { MIME_TYPES, BRAND } from '../../constants';
import { getDateInFormat } from '../../utils/date';

const ACCEPTED_TYPES = [
  '.csv', // https://stackoverflow.com/a/59219508
  '.xls',
  '.xlsx',
  '.doc',
  '.docx',
  'image/png',
  'image/jpeg',
  'image/gif',
  MIME_TYPES.PDF,
  MIME_TYPES.CSV,
  MIME_TYPES.DOC,
  MIME_TYPES.DOCX,
  MIME_TYPES.XLS,
  MIME_TYPES.XLSX,
];

const FILE_SIZE_LIMIT = 25;
const SIZE_ERROR_MIN = 'Please make sure file is not empty';
const SIZE_ERROR_MAX = `Please make sure file size is less than ${FILE_SIZE_LIMIT}MB`;

export function FileUpload({
  label,
  year,
  month,
  quarter,
  version,
  description,
  dueDate,
  type,
  file,
  onChange,
  brand,
}) {
  const hasSelectedFile = !!file?.name;
  const exampleDocUrl = getExampleDoc(type, brand);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles && acceptedFiles[0];

    if (!file.size) {
      alert(SIZE_ERROR_MIN);
      return;
    }

    if (Math.floor(file.size / 1000000) > FILE_SIZE_LIMIT) {
      alert(SIZE_ERROR_MAX);
      return;
    }

    onChange(file);
  };

  const onFileDialogCancel = () => {
    onChange(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: ACCEPTED_TYPES.join(','),
    onDrop,
    onFileDialogCancel,
  });

  return (
    <div className="file-wrapper">
      <div className="file-wrapper__left">
        <div
          {...getRootProps({
            className: classNames('file-wrapper__dropzone', {
              'file-wrapper__dropzone--selected': hasSelectedFile,
            }),
          })}
        >
          <input {...getInputProps()} />
        </div>
      </div>

      <div className="file-wrapper__right">
        <div className="file-wrapper__label">{printLabel(label, version)}</div>

        {file?.name ? (
          <div className="file-wrapper__filename" title={file?.name}>
            {file?.name}
          </div>
        ) : null}

        {year || month || quarter ? (
          <div className="file-wrapper__year-month-quarter">
            {year ? <span>Year: {year}</span> : null}

            {month ? (
              <>
                {year ? <span className="mr--sm ml--sm">|</span> : null}
                <span>Month: {month}</span>
              </>
            ) : null}

            {quarter ? (
              <>
                {year || month ? <span className="mr--sm ml--sm">|</span> : null}
                <span>Quarter: {quarter}</span>
              </>
            ) : null}
          </div>
        ) : null}

        {dueDate ? (
          <div className="file-wrapper__due-date" title={dueDate}>
            <span>Due Date: {getDateInFormat(dueDate, 'MM-dd-yyyy')}</span>
          </div>
        ) : null}

        {description ? <div className="file-wrapper__description">{description}</div> : null}

        {exampleDocUrl ? (
          <div style={{ marginTop: '10px' }}>
            <a className="link-example-doc" href={exampleDocUrl} download>
              Download Template
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
}

function printLabel(label, version) {
  return version ? `${label}, ${version}` : label;
}

function getExampleDoc(type, brand) {
  if (!type) {
    return '';
  }

  if (brand === BRAND.RC) {
    if (type === 'Business Debt Schedule') {
      return process.env.PUBLIC_URL + '/example-doc/rc/Debt_Schedule.pdf';
    }
  }

  if (type === 'MicroCap Questionnaire') {
    return process.env.PUBLIC_URL + '/example-doc/General_Business_Questionnaire_MicroCap.docx';
  }

  if (type === 'General Business Questionnaire') {
    return process.env.PUBLIC_URL + '/example-doc/General_Business_Questionnaire.docx';
  }

  return '';
}
