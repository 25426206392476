export const BRAND = {
  NONE: 'none',
  KCF: 'kcf',
  IBF: 'ibf',
  RC: 'rc',
};

export const MIME_TYPES = {
  PDF: 'application/pdf',
  CSV: 'text/csv',
  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};
